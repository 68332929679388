import {controller, attr, targets} from '@github/catalyst'
import {on} from 'delegated-events'
import {addUrlToHistoryStack} from '@github-ui/history'

function updateAchievementParameter(event: Event & {currentTarget: Element}) {
  const details = event.currentTarget
  const slug = details.getAttribute('data-achievement-slug')

  const url = new URL(window.location.href, window.location.origin)
  const params = new URLSearchParams(url.search)
  if (details.hasAttribute('open') && slug) {
    params.set('achievement', slug)
  } else {
    params.delete('achievement')
  }
  url.search = params.toString()
  addUrlToHistoryStack(url.toString())
}

on('toggle', '.js-achievement-card-details', updateAchievementParameter, {capture: true})

// Catalyst component to use a "coin flip" animation to display all unlocked tiers of an earned achievement.
//
// Expects a data-tier-count attribute to be set to the number of tier images available as children within the
// DOM. Each bage tier image must be annotated as a target for the tiers property. A flip will occur as soon as
// the component is loaded into the DOM and may be repeated by triggering the "flip" action.
//
// Usage:
//
// <achievement-badge-flip data-tier-count="3">
//   <img class="tier-badge" data-targets="achievement-badge-flip.tiers" />
//   <img class="tier-badge tier-badge--back" data-targets="achievement-badge-flip.tiers" />
//   <img class="tier-badge" data-targets="achievement-badge-flip.tiers" />
// </achievement-badge-flip>
@controller
class AchievementBadgeFlipElement extends HTMLElement {
  static attrPrefix = ''
  @attr tierCount = 0

  @targets declare tiers: HTMLElement[]

  animations: Map<HTMLElement, Animation> = new Map()

  // To implement the achievement badge flipping animation, we need to identify which keyframes -- specified as
  // progress percentages through the animation duration, [0..100] -- at which each badge tier graphic should toggle
  // its visibility. These correspond to the x-values of the cubic bezier curve used by the flipping animation at which
  // its y-value corresponds to a property value that's a multiple of 180 degrees.
  //
  // For example: With four achievement tiers to display, we have an animation that progresses the rotateY property
  // from 0 to 720 degrees. We need each tier graphic's animation to have keyframes for its opacity property at the
  // 0, 180, 360, 540, and finally 720 degree points, which occur at bezier curve y-values of 0, 0.25, 0.5, 0.75, and 1.
  // Using the inverse of the cubic bezier easing function, we can calculate that these occur at x-values of
  // 0, 9, 22, 42, and 100, so these are the keyframes that we create.
  //
  // To calculate the inverse of the cubic bezier function, I used the bezier-easing package from npm:
  // http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://github.com/gre/bezier-easing
  //
  // As written, it can only output y-values corresponding to x-values, but we can invert the curve be swapping x and
  // y values within our control points: http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://github.com/gre/bezier-easing/issues/38
  //
  // ```
  // mkdir temp && npm init -y && npm install bezier-easing
  // ```
  //
  // Then, in a node repl:
  //
  // ```
  // const BezierEasing = require("bezier-easing")
  // const inverse = BezierEasing(0, 0, 1, 0.25) // Note swapped x and y values from easing function in animation
  //
  // inverse(0 / 720) // === 0
  // inverse(180 / 720) // === 0.09
  // inverse(360 / 720) // === 0.22
  // inverse(540 / 720) // === 0.42
  // inverse(720 / 720) // === 1
  // ```
  BADGE_SIDE_KEYFRAMES = [
    // zero sides. (unused)
    [],
    // one side. (unused, we don't show half a flip)
    // rotateY values: 0, 180
    // y-values: 0, 1
    [0, 1],
    // two sides.
    // rotateY values: 0, 180, 360
    // bezier curve y-values: 0, 0.5, 1
    [0, 0.22, 1],
    // three sides.
    // rotateY values: 0, 180, 360, 540
    // bezier curve y-values: 0, 0.33, 0.66, 1
    [0, 0.13, 0.34, 1],
    // four sides.
    // rotateY values: 0, 180, 360, 540, 720
    // bezier curve y-values: 0, 0.25, 0.5, 0.75, 1
    [0, 0.09, 0.22, 0.42, 1],
    // five sides. (currently unused, we have no five-tier achievements yet)
    // rotateY values: 0, 180, 360, 540, 720, 900
    [0, 0.07, 0.16, 0.29, 0.47, 1],
  ]

  // Create a "flip" animation on the container element and opacity animations on each tier badge image.
  connectedCallback() {
    // No flipping for single-tier achievements.
    if (this.tierCount <= 1) {
      return
    }

    // Web animations API unavailable.
    if (!this.animate) {
      return
    }

    // This animation vertically rotates all badge images. The number of "flips" is governed by the maxRotation
    // property, one flip of 180 degrees for each unlocked tier.
    const containerAnimation = this.animate(
      [{transform: 'rotateY(0deg)'}, {transform: `rotateY(${this.maxRotation}deg)`}],
      {
        duration: this.duration,
        easing: 'cubic-bezier(0, 0, 0.25, 1)',
      },
    )
    this.animations.set(this, containerAnimation)

    // Create opacity animations for each tier badge image. If the expected child DOM elements are not yet loaded at
    // the time when the component is attached, use a MutationObserver to create animations for them when they are.
    if (!this.createTierAnimations()) {
      const observer = new MutationObserver((mutationList, obs) => {
        if (this.createTierAnimations()) {
          obs.disconnect()
        }
      })
      observer.observe(this, {childList: true})
    }
  }

  // Compute the common duration of all animations managed by this component: 500ms times the number of tiers.
  get duration() {
    return this.tierCount * 500
  }

  // Compute the maximum container rotation in degrees.
  get maxRotation() {
    return this.tierCount * 180
  }

  // Create new opacity animations for any child badge images that do not have them yet. Returns true if all
  // expected child elements are available in the DOM or false if more are expected to arrive.
  createTierAnimations(): boolean {
    for (const tierElement of this.tiers) {
      this.ensureTierAnimation(tierElement)
    }
    return this.tiers.length >= this.tierCount
  }

  // Create an opacity animation for a single child badge image if it has not been created yet. This animation will
  // ensure that the badge is visible during the correct "flip" it's expected to be.
  ensureTierAnimation(childElement: HTMLElement) {
    if (this.animations.has(childElement)) {
      return
    }

    const childIndex = this.tiers.indexOf(childElement)
    if (childIndex < 0) {
      return
    }

    const offsets = this.BADGE_SIDE_KEYFRAMES[this.tierCount]
    if (!offsets) {
      return
    }

    // When flipping an odd number of tiers, we need to reverse the first tier image during the final flip so that
    // it's correctly visible as the back of the last tier.
    const hasOddTiers = this.tierCount % 2 === 1

    const keyframes = offsets.map((offset, index) => {
      // Each tier image should be visible during the flip keyframes corresponding to its tier and the one immediately
      // after (so it appears as the "back" of the next flip). The first tier image must also be visible during the
      // final flip, so it correctly appears as the back of the final one.
      const beVisible =
        index === childIndex || index === childIndex + 1 || (childIndex === 0 && index === this.tierCount)
      const keyframe: Keyframe = {offset, opacity: beVisible ? 1 : 0, easing: 'step-start'}
      if (hasOddTiers && childIndex === 0) {
        // Animate the transform of the first child: 0 degrees of y-axis rotation on all keyframes except the final
        // one, when it's 180 degrees to act as the "back" of the last tier flip.
        const rotation = index === offsets.length - 1 ? 180 : 0
        keyframe.transform = `rotateY(${rotation}deg)`
      }
      return keyframe
    })

    const tierAnimation = childElement.animate(keyframes, {duration: this.duration})
    this.animations.set(childElement, tierAnimation)
  }

  // Public action method. Trigger this to restart all animations as long as none are already running.
  flip() {
    for (const animation of this.animations.values()) {
      if (animation.playState === 'running') {
        return
      }
    }

    for (const animation of this.animations.values()) {
      animation.play()
    }
  }
}
